/* eslint-disable indent */
import * as React from "react";
import CheckBoxNeoGenControlled from "../../layout/checkbox-controlled";
import SelectNeoGen from "../../layout/select-neogen";
import states from "../../sections/utilities/states.json";
import TextAreaNeoGenControlled from "../../layout/text-area-controlled";
import InputControlled from "../../layout/input-controlled";
import RadioButtonNeoGenControlled from "../../layout/radiobutton-neogen-controlled";
import FileUploadWidget from "../../layout/file-upload-widget";
import { FileUploadWithMultiple } from "../../typings/api/file-upload";
import { calculate } from "../../sections/utilities/calculate";
import CalendarWidget from "../../sections/utilities/calendar-widget";
import ButtonNeoGen from "../../layout/button-neogen";
import { Entry } from "../processflows/domain/processflow";
import { ButtonActionTypes } from "../../layout/button-creator";
import { useBreakpoint } from "../../hooks/appMedia";
import { PaymentOptions, paymentOptionsKey } from "../../billing/paymentOptions";
import { FinanceOptions } from "../../billing/financeOptions";
import { getEstimatedPayoutFromData } from "../other/actions/getEstimatedPayout";
import Datepicker from "react-tailwindcss-datepicker";
import IndustryPicker from "../../layout/IndustryPicker";
import CustomTable from "../../layout/custom-table";
import PopoverComponent from "../../layout/popover-component";
import DollarInput from "../../layout/dollar-input";
import SignatureCanvas from "react-signature-canvas";
import { useRef, useState } from "react";
import ReactDOM from "react-dom";
import { format } from "date-fns";
import { useRecoilState } from "recoil";
import userAtom from "../../atoms/userAtom";
import { getAuthTokenNoThrow } from "../../services/auth-header";
import { useCompany } from "../../companies/hooks/use-company";
import { CurrencyInput } from "../../layout/form/currency-input";
import { ProcessFlowProgressData } from "../../typings/api/processflow-progress-data";

type OnClick = (action: ButtonActionTypes) => void;

const FILE_API_URL = process.env.REACT_APP_FILE_SERVER ?? process.env.REACT_APP_API_URL ?? "https://api.clearerc.com/";

export const RenderEntry = ({
    entry,
    entryErrors,
    onChange,
    onClick,
    data,
    allEntries,
    noMargin,
    drawWithFlex,
    isMobile,
    isTablet,
    getFileKey,
    companyId,
    pfIsOptional,
    progressData,
}: {
    entry: Entry;
    entryErrors?: string[];
    onChange: (value: any) => void;
    onClick: OnClick;
    data: Record<string, any>;
    allEntries: any;
    noMargin?: boolean;
    drawWithFlex?: boolean;
    isMobile?: boolean;
    isTablet?: boolean;
    getFileKey?: (entry: Entry) => string;
    companyId?: number;
    pfIsOptional?: number;
    progressData?: ProcessFlowProgressData;
}) => {
    const estimatedAmount = getEstimatedPayoutFromData({ data });
    const user = window.localStorage.getItem("user") ? JSON.parse(window.localStorage.getItem("user") ?? "") : {};
    const isAdmin = (user?.user?.roleGroups || []).find((r: any) => r.id === 4);
    const authToken = getAuthTokenNoThrow() || "no-auth";

    if (entry.field === paymentOptionsKey) {
        return (
            <PaymentOptions
                estimatedAmount={estimatedAmount}
                defaultValue={data[entry.field]}
                onChange={(id) => {
                    onChange(id);
                }}
            />
        );
    }

    if (entry.field === "finance_options") {
        return (
            <FinanceOptions
                estimatedAmount={estimatedAmount}
                defaultValue={data[entry.field]}
                onChange={(id) => {
                    onChange(id);
                }}
            />
        );
    }

    const ContractPicker = () => {
        const [loggedInUser, setUser] = useRecoilState(userAtom);
        const [showSignatureCanvas, setShowSignatureCanvas] = useState(data[entry?.field] === undefined);
        const signatureCanvasRef = useRef(null);
        const [isEmpty, setIsEmpty] = useState(true);
        const userCompany = useCompany({ authToken, id: companyId || -1 });

        const replaceVariables = (htmlString: string, variables: { [key: string]: string }) => {
            for (const [variable, value] of Object.entries(variables)) {
                const placeholder = `{{${variable}}}`;
                htmlString = htmlString.replace(new RegExp(placeholder, "g"), value);
            }
            return htmlString;
        };

        const variables: { [key: string]: string } = {
            companyName: userCompany.data?.name || "Company name unknown",
            companyAddress: userCompany.data?.address || "Company address unknown",
            city: userCompany.data?.city || "Company city unknown",
            state: userCompany.data?.city || "Company state unknown",
            country: userCompany.data?.country || "Company country unknown",
            email: loggedInUser.email,
            ownerFirstName: loggedInUser.firstName || "-",
            ownerLastName: loggedInUser.lastName || "-",
        };

        // eslint-disable-next-line no-case-declarations
        const baseHtml = entry.value.replace(/\n/g, "<br />");
        const html: string = replaceVariables(baseHtml, variables);

        const HeaderComponent = (
            <div className="mb-3 mt-8">
                <h3 className="font-bold">Signed by:</h3>
            </div>
        );

        const NameComponent = (
            <div className="my-3">
                <p className="font-bold">
                    Name: {loggedInUser.firstName} {loggedInUser.lastName}
                </p>
                <p className="font-bold">Email: {loggedInUser.email}</p>
                <p className="font-bold">Signed at: {format(new Date(), "dd/LL/yyyy kk:mm")}</p>
            </div>
        );

        const handleCaptureSignature = async () => {
            // @ts-ignore
            const signatureDataUrl = signatureCanvasRef.current?.toDataURL();
            const img = new Image();
            img.src = signatureDataUrl;

            const headerContainer = document.createElement("div");
            ReactDOM.render(HeaderComponent, headerContainer);

            const nameContainer = document.createElement("div");
            ReactDOM.render(NameComponent, nameContainer);

            const contractElement = document.getElementById("contract");
            if (contractElement) {
                while (headerContainer.firstChild) {
                    contractElement.appendChild(headerContainer.firstChild);
                }
            }

            contractElement?.appendChild(img);

            if (contractElement) {
                while (nameContainer.firstChild) {
                    contractElement.appendChild(nameContainer.firstChild);
                }
            }

            const c = document.getElementById("contract");
            const htmlString = c?.outerHTML;
            onChange(htmlString);
            setShowSignatureCanvas(false);
        };

        return (
            <>
                <div key={entry.id} className={" " + (entry.widthOf300Cols ?? "w-full") + " mb-5"}>
                    <div
                        id="contract"
                        className="ql-snow ql-read-only !font-normal !text-sm !tracking-wider !leading-normal !text-gray-500 !not-italic !no-underline "
                        dangerouslySetInnerHTML={{ __html: data[entry.field] || html }}
                    />
                    {entry.helpDocument && (
                        <>
                            <PopoverComponent document={entry.helpDocument} />
                        </>
                    )}
                </div>
                {showSignatureCanvas && (
                    <>
                        <div className="my-4">
                            <p className="text-gray-500">Your signature</p>
                            <div className="border-gray-500 w-[500px] border-2">
                                <SignatureCanvas
                                    ref={signatureCanvasRef}
                                    penColor="black"
                                    canvasProps={{ width: 500, height: 200, className: "signature-canvas" }}
                                    onBegin={() => setIsEmpty(false)}
                                />
                            </div>
                            <ButtonNeoGen
                                type="outline"
                                className="mr-2"
                                onClick={() => {
                                    // @ts-ignore
                                    signatureCanvasRef.current?.clear();
                                    setIsEmpty(true);
                                }}
                            >
                                Clear
                            </ButtonNeoGen>
                            <ButtonNeoGen
                                onClick={handleCaptureSignature}
                                // @ts-ignore
                                disabled={isEmpty}
                                className="mt-2"
                            >
                                Save signature
                            </ButtonNeoGen>
                        </div>
                    </>
                )}
            </>
        );
    };

    let width = "";
    switch (entry.widthOf300Cols) {
        case 300:
            width = "  w-full ";
            break;
        case 200:
            width = "  w-2/3 inline-block md:pr-5 ";
            break;
        case 150:
            width = isMobile ? " w-full " : "  w-1/2 inline-block md:pr-5 ";
            break;
        case 100:
            width =
                " w-full inline-block align-top " +
                " md:align-top md:w-1/2 md:pr-5 " +
                "  lg:align-top lg:w-1/3 lg:inline-block lg:pr-5 ";
            break;
        case 75:
            width = isMobile
                ? " w-full inline-block "
                : isTablet
                ? " w-1/2 inline-block align-top pr-5 "
                : "  w-1/4 inline-block pr-5 align-top ";
            break;
        case 60:
            width = "  w-1/5 inline-block md:pr-5 h-full ";
            break;
        default:
            width = "  w-full inline-block ";
            break;
    }

    width += " my-1 top-0  left-0 h-full ";
    if (entry.hideWhenFilled && data[entry.field] && data[entry.field].length > 0) {
        width += " hidden ";
    }
    switch (entry.type) {
        case "textDisplay":
            return (
                <div className={" " + width + (noMargin ? " m-0" : " mb-5")}>
                    <p className="text-sm text-gray-500 tracking-wider">
                        <span className="text-sm text-gray-500 tracking-wider">{entry.value}: </span>
                        {/* <b>{typeof (data[entry.field] === "String") ? data[entry.field] : ""}</b> */}
                        {typeof data[entry.field] === "string" ? data[entry.field] : ""}
                        {/* {typeof data[entry.field] !== "string" && <PrintPre>{entry.field}</PrintPre>} */}
                    </p>
                </div>
            );
        case "video":
            return (
                <div className={" " + width + " mb-5"}>
                    <span className="text-sm text-gray-500 tracking-wider">{entry.value}</span>
                    <video controls width="100%">
                        <source src={FILE_API_URL + "/files/" + entry.field} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            );
        case "checkbox":
            return (
                <div className={" " + width + " mb-5"}>
                    <CheckBoxNeoGenControlled
                        setValue={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onChange(e.currentTarget.checked);
                        }}
                        value={data[entry.field]}
                        name={entry.field}
                        label={entry.value}
                        description={entry.description}
                        helpDocument={entry.helpDocument}
                        required={entry.required}
                    />
                </div>
            );
        case "reviewedBy":
            return (
                <div className={" " + width + " mb-5"}>
                    {/* <PrintPre> */}
                    {}
                    {/* </PrintPre> */}
                    <CheckBoxNeoGenControlled
                        setValue={(e: React.ChangeEvent<HTMLInputElement>) => {
                            onChange(e.currentTarget.checked + "::" + user.user.email);
                        }}
                        value={JSON.parse(data[entry.field]?.split("::")?.[0] ?? "false")}
                        name={entry.field}
                        label={
                            entry.value +
                            " " +
                            (JSON.parse(data[entry.field]?.split("::")?.[0] ?? "false")
                                ? "Reviewed By: " + data[entry.field]?.split("::")?.[1] ?? ""
                                : "")
                        }
                        description={entry.description}
                        helpDocument={entry.helpDocument}
                        required={entry.required}
                    />
                </div>
            );
        case "industryPicker":
            return (
                <div className={" " + width + " mb-5 align-bottom pb-1"}>
                    <IndustryPicker
                        label={entry.value}
                        setIndustries={(e: number[] | null | number) => {
                            onChange(e);
                        }}
                        industries={data[entry.field]}
                    />
                    {entry.description && <p className="text-sm text-gray-500 tracking-wider">{entry.description}</p>}
                </div>
            );
        case "state":
            return (
                <div className={" " + width + " mb-5"}>
                    <SelectNeoGen
                        onChange={(e: string | number) => {
                            onChange(e.toString());
                        }}
                        value={data[entry.field]}
                        options={states}
                        label={entry.value}
                        required={entry.required}
                    />
                    <p className="text-sm text-gray-500 tracking-wider">{entry.description}</p>
                </div>
            );
        case "textarea":
            return (
                <div className={" " + width + " mb-5"}>
                    <TextAreaNeoGenControlled
                        setValue={(e: string) => {
                            onChange(e);
                        }}
                        value={data?.[entry.field] ?? entry.defaultVal ?? ""}
                        name={entry.field}
                        label={entry.value}
                        description={entry.description}
                        required={entry.required}
                        helpDocument={entry.helpDocument}
                        autoComplete="off"
                    />
                </div>
            );
        case "textfield": {
            return (
                <div className={" " + width + " self-start"}>
                    <InputControlled
                        setValue={(e: string) => {
                            onChange(e);
                        }}
                        required={entry.required}
                        password={entry.password}
                        type={entry.password ? "password" : "text"}
                        value={data?.[entry.field] ?? entry.defaultVal ?? ""}
                        name={entry.field}
                        label={entry.value}
                        helpDocument={entry.helpDocument}
                        description={entry.description}
                        errors={entryErrors}
                        chipLabel={data?.[`${entry.field}_chipLabel`]}
                        autoComplete="off"
                    />
                </div>
            );
        }
        case "dollar":
            return (
                <div className={" " + width + " self-start"}>
                    <p className="text-sm text-gray-500 tracking-wider">{entry.value}</p>
                    <DollarInput
                        setValue={(e: string) => {
                            onChange(e);
                        }}
                        value={data?.[entry.field] ?? entry.defaultVal ?? ""}
                        autoComplete="off"
                    />
                </div>
            );
        case "currency":
            return (
                <div className={" " + width + " self-start"}>
                    <div className="flex justify-between items-center mb-1">
                        <p className="text-sm text-gray-400 tracking-wider">
                            {entry.value}
                            {entry.required && (
                                <span
                                    className="inline-block text-lg leading-none text-red-500 font-extralight pl-[1px] mr-[1px]"
                                    style={{ top: 10 }}
                                >
                                    *
                                </span>
                            )}
                        </p>
                        {data?.[`${entry.field}_chipLabel`] && (
                            <div
                                className={`text-xs rounded-xl ${
                                    data?.[`${entry.field}_chipLabel`] === "Qualified"
                                        ? " bg-green-100 border-green-400 text-green-600 "
                                        : data?.[`${entry.field}_chipLabel`] === "Not qualified"
                                        ? " bg-red-100 border-red-400 text-red-600 "
                                        : " bg-blue-100 border-blue-400 text-blue-600 "
                                } px-2 py-1`}
                            >
                                {data?.[`${entry.field}_chipLabel`]}
                            </div>
                        )}
                    </div>
                    <CurrencyInput
                        required={entry.required}
                        onChange={(e) => {
                            onChange(Number.parseFloat(e.target.value));
                        }}
                        value={data?.[entry.field] ?? entry.defaultVal ?? ""}
                        autoComplete="off"
                    />
                </div>
            );
        case "customTable":
            return (
                <div className={" " + width + " mb-5"}>
                    <p className="text-sm text-gray-500 tracking-wider">{entry.value}</p>
                    <CustomTable
                        data={data?.[entry.field]?.data ?? []}
                        columns={data?.[entry.field]?.columns ?? []}
                        setData={(e: any) => {
                            onChange({ data: e, columns: data?.[entry.field]?.columns ?? [] });
                        }}
                        setColumns={(e: any) => {
                            onChange({ data: data?.[entry.field]?.data, columns: e ?? [] });
                        }}
                    />
                </div>
            );

        case "valueCalculator":
            // Doesn't draw anything - it gets included in HTML
            return <div />;
        case "yesNo":
            return (
                <div className={" " + width + "  mb-5"}>
                    <RadioButtonNeoGenControlled
                        onChange={(e: string | number) => {
                            onChange(e.toString());
                        }}
                        value={data?.[entry.field]}
                        options={[
                            { id: "1", name: "Yes" },
                            { id: "0", name: "No" },
                        ]}
                        id={entry.field}
                        label={entry.value}
                        helpDocument={entry.helpDocument}
                        required={entry.required}
                    />
                    <p className="text-sm text-gray-500 tracking-wider">{entry.description}</p>
                </div>
            );
        case "selectBox":
            return (
                <div className={" " + width + "  mb-5 "}>
                    <RadioButtonNeoGenControlled
                        onChange={(e: string | number) => {
                            onChange(e.toString());
                        }}
                        value={data?.[entry.field]}
                        options={entry.selectBoxEntries?.map((selectBoxEntry: any) => {
                            return {
                                id: selectBoxEntry,
                                name: selectBoxEntry,
                            };
                        })}
                        helpDocument={entry.helpDocument}
                        id={entry.field}
                        label={entry.value}
                        required={entry.required}
                    />
                    <p className="text-sm text-gray-500 tracking-wider">{entry.description}</p>
                </div>
            );
        case "fileUpload":
            width =
                entry.hideWhenFilled && data[entry?.field]?.multipleFiles?.length > 0
                    ? " hidden "
                    : " w-full lg:w-1/2 min-[1048px]:w-1/3 min-[2000px]:w-1/4 inline-block md:pr-5 ";
            return (
                <div className={"text-left md:text-center " + width + " "}>
                    <FileUploadWidget
                        entry={entry}
                        fileDetails={data?.[entry.field]}
                        helpDocument={entry.helpDocument}
                        name={entry.field}
                        label={entry.value}
                        setFileDetails={(fileDetails: FileUploadWithMultiple) => {
                            onChange(fileDetails);
                        }}
                        required={entry.required}
                        fileKey={getFileKey ? getFileKey(entry) : undefined}
                    />
                    <p className="text-xs mb-3 text-gray-500 tracking-wider">{entry.description}</p>
                </div>
            );
        case "button": {
            const button = JSON.parse(entry.value);
            const disableIfContractUnsignedButton =
                allEntries.some((item: any) => item.type === "contractPicker") &&
                !Object.values(data).some((value) => value.includes('<div id="contract"')); // if allEntries contains type: "contractPicker" then data must contain <div id="contract"

            const emptyAndNotHiddenFields =
                pfIsOptional === 0
                    ? (allEntries || []).map((item: any) => {
                          const id = item?.id;
                          if (
                              [undefined, null, "", " "].includes(data[id]) &&
                              item.required === true &&
                              [
                                  "nextStep",
                                  "nextStepAndSendSlackMessage",
                                  "nextStage",
                                  "createAccount",
                                  "createAccountAndLogin",
                                  "createAccountLoginAndRedirect",
                                  "createAccountSendConfirmation",
                                  "login",
                              ].includes(button.action) &&
                              !progressData?.entriesByField[id]?.isHidden
                          ) {
                              return { id, empty: true };
                          } else {
                              return { id, empty: false };
                          }
                      })
                    : [];

            const disableIfPfIsNotOptional = emptyAndNotHiddenFields.some((v: any) => v.empty === true);
            const disableButton = !isAdmin && (disableIfContractUnsignedButton || disableIfPfIsNotOptional);

            return (
                <div className={" inline-block mr-5 mb-5" + " "}>
                    <ButtonNeoGen
                        icon={
                            (button.action === "nextStep" && "fas fa-chevron-right") ||
                            (button.action === "previousStep" && "fas fa-chevron-left") ||
                            (button.action === "nextStage" && "fas fa-chevron-right") ||
                            (button.action === "previousStage" && "fas fa-chevron-left") ||
                            (button.action === "submit" && "fal fa-check") ||
                            (button.action === "save" && "fal fa-save") ||
                            (button.action === "cancel" && "fal fa-times") ||
                            (button.action === "reset" && "fal fa-undo") ||
                            (button.action === "custom" && button.icon)
                        }
                        type={button.colorType !== "custom" ? button.colorType : undefined}
                        disabled={disableButton}
                        size={button.size}
                        onClick={() => {
                            // TODO: Change this to an atom
                            localStorage.setItem("button", JSON.stringify(button));
                            onClick(button.action);
                        }}
                        iconAlign={["previousStep", "previousStage"].includes(button.action) ? "left" : "right"}
                        text={button.label}
                        tooltipText={
                            disableIfPfIsNotOptional
                                ? `Fields: ${emptyAndNotHiddenFields
                                      .filter((v: any) => v.empty === true)
                                      .map((v: any) => v.id)
                                      .join(", ")} needs to be filled in.`
                                : undefined
                        }
                    />
                </div>
            );
        }
        case "contractPicker": {
            return <ContractPicker />;
        }
        case "wysiwyg": {
            // An HTML string

            // In the HTML sections you can click a button to insert a field's value
            // Basically the way it works is that you have a button that inserts the '
            // field name inside double curly braces. When we are displaying the HTML
            // we replace the double curly braces with the actual value of the field.
            // This could be the value the customer entered or it could be a calculation
            // of other fields.
            //
            // Replace {{fieldName}} with the value of the field with the same name
            // across all the questions in the current progress flow group.
            // console.info("Drawing: ",entry)

            // eslint-disable-next-line no-case-declarations
            const html = entry.value
                .replace(/\[\[(.*?)\]\]/g, (_match: string, fieldFound: string) => {
                    // Replace [[filename]] with a link to the video
                    return `
                    <div className={" " + width + " mb-5"}>
                    <video  controls>
                        <source
                            src="${FILE_API_URL}/files/${fieldFound}" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>

                </div>
                        `;
                })
                .replace(/{{(.*?)}}/g, (_match: string, fieldFound: string) => {
                    // Look for the field value in the current step. If it's not there then
                    // either the customer hasn't entered it yet or it's not in the current
                    // step. So we look for it in the previous steps.
                    // The other option is that the field is a calculation, in which case
                    // we need to look for the field values to calculate it.
                    // The val variable will hold the value of the field and its details.
                    const val: Entry = data?.[fieldFound];

                    // If the field type is a state, return the long form state name.
                    if (val?.type === "state") {
                        return states.find((s: any) => s.id === val.fieldValue)?.name ?? "";
                    }

                    // If the field type is a checkbox, return it as Yes or No
                    if (val?.type === "checkbox") {
                        return val.fieldValue ? "Yes" : "No";
                    }

                    // If we didn't find the field value it might just have not been filled out yet.
                    // We should know from the field type if it's a calculation or not.

                    // First look through all entries and see if we can find the field name.
                    const field: Entry | undefined = (allEntries ?? []).find((e: any) => {
                        return e.id === fieldFound;
                    });

                    if (!val && field?.type === "valueCalculator") {
                        try {
                            let total = 0;
                            const foundFull = allEntries?.find((p: any) => p.id === fieldFound);
                            const found = foundFull.value;

                            if (found) {
                                for (const calculation of JSON.parse(found)) {
                                    let val1 = 0;
                                    let val2 = 0;
                                    if (calculation.field1 === "**num") {
                                        val1 = calculation.field1number;
                                    } else {
                                        val1 = data?.[calculation.field1];
                                    }
                                    if (calculation.field2 === "**num") {
                                        val2 = calculation.field2number;
                                    } else {
                                        val2 = data?.[calculation.field2];
                                    }
                                    const opResult = calculate(val1, val2, calculation.operator);
                                    total += opResult;
                                }
                            }
                            return (Number.isNaN(total) ? 0 : total).toLocaleString();
                        } catch (e) {
                            return "(<></>)";
                        }
                    }
                    return val?.fieldValue ?? "";
                })
                .replace(/\n/g, "<br />");
            return (
                <div key={entry.id} className={" " + (entry.widthOf300Cols ?? "w-full") + " mb-5"}>
                    <div
                        className="ql-snow ql-read-only !font-normal !text-sm !tracking-wider !leading-normal !text-gray-500 !not-italic !no-underline "
                        dangerouslySetInnerHTML={{ __html: html }}
                    />
                    {entry.helpDocument && (
                        <>
                            <PopoverComponent document={entry.helpDocument} />
                        </>
                    )}
                </div>
            );
        }
        case "datePicker": {
            return (
                <div className={" " + width + " "}>
                    <CalendarWidget
                        onChange={(e: Date) => {
                            onChange(e);
                        }}
                        value={data?.[entry.field] && new Date(data[entry.field])}
                        label={entry.value}
                        description={entry.description || ""}
                    />
                </div>
            );
        }
        case "daterangePicker": {
            const value = data?.[entry.field]
                ? { startDate: new Date(data[entry.field].startDate), endDate: new Date(data[entry.field].endDate) }
                : { startDate: new Date(), endDate: new Date() };
            return (
                <div className={" " + width + " self-start"}>
                    <span className="text-sm text-gray-500 tracking-wider">{entry.value}</span>
                    <span className="text-sm text-gray-500 tracking-wider">{entry.description}</span>
                    <Datepicker
                        onChange={(e: any) => {
                            onChange(e);
                        }}
                        value={value}
                        // label={entry.value}
                        // description={entry.description || ""}
                        displayFormat={"MM/DD/YYYY"}
                    />
                </div>
            );
        }
        default:
            return <div></div>;
    }
};

export const Entries = ({
    entries,
    entryErrors,
    onChange,
    onClick,
    data,
    noMargin,
    drawWithFlex,
    getFileKey,
    companyId,
    pfIsOptional,
    progressData,
}: {
    entries: Entry[];
    entryErrors?: Record<string, string[]>;
    onChange: ({ entry, value }: { entry: Entry; value: any }) => void;
    onClick: OnClick;
    data: Record<string, any>;
    noMargin?: boolean;
    drawWithFlex?: boolean;
    getFileKey?: (entry: Entry) => string;
    companyId?: number;
    pfIsOptional?: number;
    progressData?: ProcessFlowProgressData;
}) => {
    const breakpoints = useBreakpoint();
    const isMobile = breakpoints.breakpoint === "mobile";
    const isTablet = breakpoints.breakpoint === "tablet";

    return (
        <div className={drawWithFlex ? "grid-cols-2 w-full overflow-hidden grid " : ""}>
            {entries.map((entry) => {
                return (
                    <span key={entry.id} className={drawWithFlex ? " " : ""}>
                        <RenderEntry
                            entry={entry}
                            entryErrors={entryErrors?.[entry.field]}
                            data={data}
                            onChange={(value) => onChange({ entry, value })}
                            onClick={onClick}
                            allEntries={entries}
                            noMargin={noMargin}
                            isMobile={isMobile}
                            isTablet={isTablet}
                            getFileKey={getFileKey}
                            companyId={companyId}
                            pfIsOptional={pfIsOptional}
                            progressData={progressData}
                        />
                    </span>
                );
            })}
        </div>
    );
};
